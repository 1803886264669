<template>
  <div>
    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          outlined
          color="red"
          x-small
          @click="openDialog()"
          ><v-icon small>delete</v-icon></v-btn
        >
      </template>
      <span>Remover o arquivo {{ item.fileName }}?</span>
    </v-tooltip>

    <v-snackbar v-model="snackbar" :timeout="timeout">
      <v-container>
        {{ snackBarMessage }}
      </v-container>
    </v-snackbar>

    <v-dialog v-model="dialog" width="600">
        <v-card outlined>
          <v-card-title class="my-3">
            <v-row justify="center">
              <div>Deletar Arquivo</div>
            </v-row>
          </v-card-title>
          <v-card-text class="mt-3">
            <v-row justify="center">
              <div>
                Deseja mesmo deletar o arquivo
                <strong>{{ item.fileName }}</strong> ?
              </div>
              <div>A operação não poderá ser desfeita!</div>
            </v-row>
          </v-card-text>
          <v-card-actions class="my-3">
            <v-row justify="center" align="center" wrap>
              <v-col cols="6" class="text-center">
                <v-btn
                  outlined
                  color="red"
                  :disabled="disabled"
                  @click="dialog = false"
                  >Cancelar <v-icon color="red">close</v-icon></v-btn
                >
              </v-col>
              <v-col cols="6" class="text-center">
                <v-btn
                  outlined
                  color="green"
                  :disabled="disabled"
                  @click="delAsyncDeleteFile()"
                  >Remover <v-icon color="green">delete</v-icon></v-btn
                >
              </v-col>
              <v-col cols="12" class="text-center">
                <v-progress-linear
                  color="red"
                  indeterminate
                  v-show="disabled"
                ></v-progress-linear>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
    </v-dialog>
    </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "FileDelete",
  props: ["file"],
  data() {
    return {
      item: {},
      snackbar: false,
      timeout: 3000,
      snackBarMessage: "",
      dialog: false,
      disabled: false,
    };
  },

  created() {
    this.item = this.file;
  },

  methods: {
    ...mapActions("file", ["ActionDeleteFile"]),
    async delAsyncDeleteFile() {
      let message;
      if (this.item.id != undefined) {
        try {
          this.disabled = true;
          await this.ActionDeleteFile(this.item.id);
          message = "Arquivo removido com sucesso.";
          this.dialog = false;
        } catch (err) {
          console.error(err);
          message = "Erro ao tentar remover o arquivo!";
        } finally {
          this.snackBarMessage = message;
          this.snackbar = true;
          this.disabled = false;
        }
      }
    },

    openDialog() {
      this.dialog = true;
    },
  },

  watch: {
    file(newFile) {
      this.item = newFile;
    },
  },
};
</script>

<style>
</style>