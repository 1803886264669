<template>
  <v-row justify="start" align="center">
    <v-col cols="12" sm="6">
      <h3>Documentos</h3>
      <hr />
    </v-col>
    <v-col cols="12" sm="6" class="text-rigth" v-if="readOnly == false">
      <FileUpload></FileUpload>
    </v-col>

    <v-col cols="12">
      <v-data-table
        :items="files"
        :headers="headers"
        :loading="loading"
        :items-per-page="files.length"
        hide-default-footer
        loading-text="Buscando arquivos do usuário..."
        no-data-text="Não foram encontrados arquivos."
      >
        <template v-slot:item="props">
          <tr>
            <td class="text-xs-left">{{ props.item.fileName }}</td>
            <td class="text-xs-left">{{ props.item.fileType }}</td>
            <td class="text-xs-left">{{ dmyh(props.item.createdAt) }}</td>
            <td class="text-xs-left">
              <FileDownload :file="props.item"></FileDownload>
            </td>
            <td class="text-xs-left">
              <span v-if="readonly == false">
                <FileDelete :file="props.item"></FileDelete>
              </span>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapState } from "vuex";

import FileDelete from "@/components/common/file/FileDelete";
import FileDownload from "@/components/common/file/FileDownload";
import FileUpload from "@/components/common/file/FileUpload";
import { formatDate } from "@/utils/formatDate";

export default {
  name: "FilesTable",
  components: { FileDelete, FileDownload, FileUpload },
  props: {
    username: { type: String, required: false },
    readonly: { type: Boolean, default: false },
  },
  data() {
    return {
      user: {},
      headers: [
        { sortable: true, text: "Arquivo", value: "fileName", align: "left" },
        {
          sortable: true,
          text: "Tipo Documento",
          value: "fileType",
          align: "left",
        },
        {
          sortable: true,
          text: "Criado em",
          value: "createdAt",
          align: "left",
        },
        { sortable: false, text: "Download", value: "", align: "left" },
        { sortable: false, text: "Deletar", value: "", align: "left" },
      ],
      loading: false,
      readOnly: false,
      userName: "",
    };
  },

  created() {
    if (!this.username) {
      this.readOnly = this.$route.query.readonly;
      this.userName = this.$route.query.username;
      this.getAsyncFiles(this.userName);
    } else {
      this.readOnly = this.readonly;
      this.getAsyncFiles(this.username);
    }
  },

  computed: {
    ...mapState("file", ["files"]),
  },

  methods: {
    ...mapActions("file", ["ActionGetFiles"]),

    async getAsyncFiles(username) {
      try {
        await this.ActionGetFiles(username);
      } catch (e) {
        console.error(e);
      }
    },

    dmyh(date) {
      return formatDate.dmyh(date);
    },
  },

  watch: {
    username(newValue) {
      this.getAsyncFiles(newValue);
    },
  },
};
</script>

<style>
</style>