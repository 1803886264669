var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-tooltip",
        {
          attrs: { top: "" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function(ref) {
                var on = ref.on
                var attrs = ref.attrs
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      _vm._b(
                        {
                          attrs: { outlined: "", color: "red", "x-small": "" },
                          on: {
                            click: function($event) {
                              return _vm.openDialog()
                            }
                          }
                        },
                        "v-btn",
                        attrs,
                        false
                      ),
                      on
                    ),
                    [
                      _c("v-icon", { attrs: { small: "" } }, [_vm._v("delete")])
                    ],
                    1
                  )
                ]
              }
            }
          ])
        },
        [
          _c("span", [
            _vm._v("Remover o arquivo " + _vm._s(_vm.item.fileName) + "?")
          ])
        ]
      ),
      _c(
        "v-snackbar",
        {
          attrs: { timeout: _vm.timeout },
          model: {
            value: _vm.snackbar,
            callback: function($$v) {
              _vm.snackbar = $$v
            },
            expression: "snackbar"
          }
        },
        [_c("v-container", [_vm._v(" " + _vm._s(_vm.snackBarMessage) + " ")])],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { width: "600" },
          model: {
            value: _vm.dialog,
            callback: function($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog"
          }
        },
        [
          _c(
            "v-card",
            { attrs: { outlined: "" } },
            [
              _c(
                "v-card-title",
                { staticClass: "my-3" },
                [
                  _c("v-row", { attrs: { justify: "center" } }, [
                    _c("div", [_vm._v("Deletar Arquivo")])
                  ])
                ],
                1
              ),
              _c(
                "v-card-text",
                { staticClass: "mt-3" },
                [
                  _c("v-row", { attrs: { justify: "center" } }, [
                    _c("div", [
                      _vm._v(" Deseja mesmo deletar o arquivo "),
                      _c("strong", [_vm._v(_vm._s(_vm.item.fileName))]),
                      _vm._v(" ? ")
                    ]),
                    _c("div", [_vm._v("A operação não poderá ser desfeita!")])
                  ])
                ],
                1
              ),
              _c(
                "v-card-actions",
                { staticClass: "my-3" },
                [
                  _c(
                    "v-row",
                    { attrs: { justify: "center", align: "center", wrap: "" } },
                    [
                      _c(
                        "v-col",
                        { staticClass: "text-center", attrs: { cols: "6" } },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                outlined: "",
                                color: "red",
                                disabled: _vm.disabled
                              },
                              on: {
                                click: function($event) {
                                  _vm.dialog = false
                                }
                              }
                            },
                            [
                              _vm._v("Cancelar "),
                              _c("v-icon", { attrs: { color: "red" } }, [
                                _vm._v("close")
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { staticClass: "text-center", attrs: { cols: "6" } },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                outlined: "",
                                color: "green",
                                disabled: _vm.disabled
                              },
                              on: {
                                click: function($event) {
                                  return _vm.delAsyncDeleteFile()
                                }
                              }
                            },
                            [
                              _vm._v("Remover "),
                              _c("v-icon", { attrs: { color: "green" } }, [
                                _vm._v("delete")
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { staticClass: "text-center", attrs: { cols: "12" } },
                        [
                          _c("v-progress-linear", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.disabled,
                                expression: "disabled"
                              }
                            ],
                            attrs: { color: "red", indeterminate: "" }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }