var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    { attrs: { justify: "start", align: "center" } },
    [
      _c("v-col", { attrs: { cols: "12", sm: "6" } }, [
        _c("h3", [_vm._v("Documentos")]),
        _c("hr")
      ]),
      _vm.readOnly == false
        ? _c(
            "v-col",
            { staticClass: "text-rigth", attrs: { cols: "12", sm: "6" } },
            [_c("FileUpload")],
            1
          )
        : _vm._e(),
      _c(
        "v-col",
        { attrs: { cols: "12" } },
        [
          _c("v-data-table", {
            attrs: {
              items: _vm.files,
              headers: _vm.headers,
              loading: _vm.loading,
              "items-per-page": _vm.files.length,
              "hide-default-footer": "",
              "loading-text": "Buscando arquivos do usuário...",
              "no-data-text": "Não foram encontrados arquivos."
            },
            scopedSlots: _vm._u([
              {
                key: "item",
                fn: function(props) {
                  return [
                    _c("tr", [
                      _c("td", { staticClass: "text-xs-left" }, [
                        _vm._v(_vm._s(props.item.fileName))
                      ]),
                      _c("td", { staticClass: "text-xs-left" }, [
                        _vm._v(_vm._s(props.item.fileType))
                      ]),
                      _c("td", { staticClass: "text-xs-left" }, [
                        _vm._v(_vm._s(_vm.dmyh(props.item.createdAt)))
                      ]),
                      _c(
                        "td",
                        { staticClass: "text-xs-left" },
                        [_c("FileDownload", { attrs: { file: props.item } })],
                        1
                      ),
                      _c("td", { staticClass: "text-xs-left" }, [
                        _vm.readonly == false
                          ? _c(
                              "span",
                              [
                                _c("FileDelete", {
                                  attrs: { file: props.item }
                                })
                              ],
                              1
                            )
                          : _vm._e()
                      ])
                    ])
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }